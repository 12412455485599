import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Create this CSS file
import MyLogo from '../Assets/Logo.png';

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolling ? 'scrolling' : ''}`}>
      <Link to="/" className="logo">
        <img src={MyLogo} alt="logo" />
      </Link>

      {/* Add the contact us button here */}
      <Link to="/contact-us" className="contact-btn">
        Contact Us
      </Link>
    </nav>
  );
};

export default Navbar;