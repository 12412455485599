import React, { useState } from 'react';
import './contact.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    type: '',
    description: '',
    mobileNumber: '', // New field for mobile number
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Replace with your actual Formspree endpoint URL
      const response = await fetch('https://formspree.io/f/xqkrwerk', {
        method: 'POST',
        body: JSON.stringify(formData), // Body should be stringified for Formspree
      });

      if (response.ok) {
        alert('Your message has been sent successfully!');
        setFormData({ name: '', company: '', type: '', description: '', mobileNumber: '' });
      } else {
        alert('Error sending message. Please try again.');
        // Optional: Log the error details to the console for debugging
      }
    } catch (error) {
      alert('An unexpected error occurred. Please try again later.');
      // Optional: Log the error details to the console for debugging
    }
  };

  return (
    <div className="container-form">
      <div className="form-section">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="company">Company Name:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            required
            onChange={handleChange}
          />

          <label htmlFor="type">Type of Business:</label>
          <input
            type="text"
            id="type"
            name="type"
            value={formData.type}
            required
            onChange={handleChange}
          />
        <label htmlFor="mobileNumber">Mobile Number:</label>
          <input
            type="tel" // Use "tel" for phone number input
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />
          <label htmlFor="description">Requirements:</label>
          <textarea
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />

          
              <button type="submit">Send Message</button>
            </form>
          </div>
          <div className="info-section">
            <h2>Location</h2>
            <iframe
  width="820"
  height="820"
  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=virtual%20business%20solutions+(Virtual%20Business%20Solutions)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  title="Google Maps location"
  allowFullScreen
  style={{ marginHeight: '0', marginWidth: '0' }}  ></iframe>
           <h2  style={{ paddingTop: '1rem' }}>Contact Information</h2>
           
                <p style={{ paddingTop: '1rem' }}> Address: Flat # 4s, Second Floor, New Shaheen Plaza, Chandni Chowk, Rawalpindi, 46000.</p>
            
                <p> Mobile/WhatsApp: +92 (312) 6777767</p>
              
                <p> Email: sales@vbs.com.pk</p>
                
                <p> Country: Pakistan</p>
             
          </div>
        </div>
      );
    };

export default ContactUs;