import React from 'react';
import './footer.css';
import './fontawesome.css';
import MyLogo from '../Assets/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMobilePhone, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';
const SiteFooter = () => {
  

  return (
    
    <footer className="footer">
      
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            
              {/* Add your logo image here */}
              <img src={MyLogo} alt="LogoFooter" />
              </div>
              <div className="col-md-4">
            <p>
              {/* Add your company description here */}
              At Virtual Business Solutions (SMC-Private) Limited, we create custom software and ERP solutions that empower businesses. Our expert team focuses on innovation, quality, and scalability. Partner with us to transform your business with cutting-edge technology! 😊
            </p>
            </div>
          
          <div className="col-md-4">
            <ul className="list-unstyled">
            <li>
                <FontAwesomeIcon icon={faHome} /> {/* Display the home icon */}
                <span> Address:</span> Flat # 4s, Second Floor, New Shaheen Plaza, Chandni Chowk, Rawalpindi, 46000.
            </li>
              <li>
              <FontAwesomeIcon icon={faMobilePhone} />
                <span> Mobile:</span> +92 (312) 6777767
              </li>
              <li>
              <FontAwesomeIcon icon={faWhatsapp} />
                <span> Whatsapp:</span> +92 (312) 6777767
              </li>
              <li>
              <FontAwesomeIcon icon={faGlobe} />
                <span> Country:</span> Pakistan
              </li>
            </ul>
          </div>
        </div>
        <script src="https://kit.fontawesome.com/c7b6e0d778.js" crossorigin="anonymous"></script>
        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} Virtual Business Solutions (SMC-Private) Limited. All Rights Reserved.</p>
          <ul className="social-icons">
         
          <li >
            <a className='SFB'  href='https://www.facebook.com/virtualbs' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} /> {/* Display the home icon */}
            </a>
            </li>
            <li >
            <a className='SIN' href='https://www.instagram.com/vbs_pakistan/' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} /> {/* Display the home icon */}
            </a>
            </li>
            <li >
            <a className='SWA' href='https://wa.me/message/ZYOZM4MFDNOSG1?src=qr' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} /> {/* Display the home icon */}
            </a>
            </li>
            <li >
            <a className='SYT' href='https://www.youtube.com/@vbs3449' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} /> {/* Display the home icon */}
            </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
