import React, { useEffect, useRef, useState } from 'react';

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    // Store the current reference in a variable to prevent stale values
    const element = containerRef.current;

    if (element) {
      const observer = new IntersectionObserver(entries => {
        setVisible(entries[0].isIntersecting); // Update visibility based on intersection
      });

      observer.observe(element);

      // Cleanup function to detach the observer when the component unmounts
      return () => {
        if (element) {
          observer.unobserve(element);
        }
      };
    }
  }, []); // Empty dependency array for optimal performance

  return (
    <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={containerRef}>
      {props.children}
    </div>
  );
}

export default FadeInSection;
