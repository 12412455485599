import React from 'react';
import './Card.css';
import image1 from '../Assets/Retail.jpg';
import image2 from '../Assets/Restaurant.jpg';
import image3 from '../Assets/Pharmacy.jpg';
import image4 from '../Assets/Distribution.jpg';
import image5 from '../Assets/Production.jpg';
import image6 from '../Assets/Car.jpg';
import image7 from '../Assets/Tailor.jpg';
import image8 from '../Assets/Education.jpg';
import image9 from '../Assets/Real.jpg';
import image10 from '../Assets/Medical.jpg';
import image11 from '../Assets/club.jpg';
import image12 from '../Assets/Asset.jpg';
import image13 from '../Assets/Hr.jpg';
import image14 from '../Assets/Accounting.jpg';
import image15 from '../Assets/Hospital.jpg';
import image16 from '../Assets/Transport.jpg';
import FadeInSection from './FadeInSection';

const Card = () => {
  // Your data source (replace with your actual data)
  const cardsData = [
    {
      id: 1,
      title: 'Retail Based POS',
      description: 'Streamline your retail operations with our intuitive POS system, designed to enhance customer experience and manage sales efficiently.',
      imageUrl: image1,
    },
    {
      id: 2,
      title: 'Restaurant/Cafe POS',
      description: 'Elevate your food service with a POS system that simplifies order management, payment processing, and kitchen coordination.',
      imageUrl: image2,
    },
    {
      id: 3,
      title: 'Pharmacy POS',
      description: 'Our specialized POS system for pharmacies ensures accurate prescription handling, inventory management, and seamless checkout processes.',
      imageUrl: image3,
    },
    {
      id: 4,
      title: 'Distribution POS and ERP',
      description: 'Integrate your distribution network with a robust POS and ERP solution, optimizing supply chain management and real-time data analysis.',
      imageUrl: image4,
    },
    {
      id: 5,
      title: 'Food Production ERP',
      description: 'Control every aspect of your food production with an ERP system tailored to manage inventory, compliance, and quality control.',
      imageUrl: image5,
    },
    {
      id: 6,
      title: 'Vehicle Rental ERP',
      description: 'Manage your vehicle rental business effortlessly with an ERP system that streamlines fleet management, booking, and maintenance schedules.',
      imageUrl: image6,
    },
    {
      id: 7,
      title: 'Tailor Shop POS',
      description: 'Customize your tailoring business with a POS system that offers client management, order tracking, and financial reporting.',
      imageUrl: image7,
    },
    {
      id: 8,
      title: 'Educational Institute ERP',
      description: 'Enhance educational administration with an ERP system designed for student management, course scheduling, and academic performance analysis.',
      imageUrl: image8,
    },
    {
      id: 9,
      title: 'Real Estate ERP',
      description: 'Our Real Estate ERP solution provides comprehensive management of properties, clients, and financial transactions for real estate professionals.',
      imageUrl: image9,
    },
    {
      id: 10,
      title: 'Medical Clinic ERP',
      description: 'Streamline clinic operations with an ERP system that supports patient records, appointment scheduling, and billing management.',
      imageUrl: image10,
    },
    {
      id: 11,
      title: 'Club Management Software',
      description: "Manage your club's memberships, events, and facilities with software designed for efficiency and member engagement.",
      imageUrl: image11,
    },
    {
      id: 12,
      title: 'Asset Management Software',
      description: "Optimize asset utilization and maintenance with software that provides real-time tracking and valuation of your company's assets.",
      imageUrl: image12,
    },
    {
      id: 13,
      title: 'HR Management Software',
      description: 'Simplify human resources processes with software that manages recruitment, payroll, and employee performance.',
      imageUrl: image13,
    },
    {
      id: 14,
      title: 'Accounting/Finance Software',
      description: 'Keep your finances in check with software that offers robust accounting features, financial reporting, and budget management.',
      imageUrl: image14,
    },
    {
      id: 15,
      title: 'Hospital Management Software',
      description: 'Enhance patient care with a comprehensive hospital management system that integrates all aspects of hospital administration.',
      imageUrl: image15,
    },
    {
      id: 16,
      title: 'Transport Business ERP',
      description: 'Streamline your transport operations with an ERP system that manages logistics, fleet maintenance, and route optimization.',
      imageUrl: image16,
    },
    // Add more card objects here
  ];

  return (
    <div className="card-container">
        {cardsData.map((card) => (
            <FadeInSection key={card.id}>
                <div className="card">
                    <img src={card.imageUrl} alt={card.title} />
                    <h3>{card.title}</h3>
                    <p>{card.description}</p>
                </div>
            </FadeInSection>
        ))}
    </div>
);
};

export default Card;
