import logo from './Assets/cover.png';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import React from 'react';
import Card from './components/Card';
import LogoSlider1 from './components/Slider';
import SiteFooter from './components/footer';
import ContactUs from './components/Contact';



function App() {
  return (
    
    <div className="App">
       <Router>
       <Navbar />
            <Routes>
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/" element={
              <div>
                <header className="App-header">
                <img src={logo} className="App-logo" alt="download" />
        
                
              </header>
              <container className='card-container'>
                
                <h2>Our Services</h2>
                <Card/>
        
                <h2>About Us</h2>
                <p className='para'>
                        At Virtual Business Solutions (SMC-Private) Limited, we are dedicated to crafting bespoke software and ERP solutions that empower your business. Our team of expert developers and strategists specialize in creating custom solutions that streamline operations, enhance productivity, and drive growth. With a keen focus on innovation and quality, we deliver systems that are not only efficient but also scalable, ensuring they evolve with your business. Our commitment to excellence and customer satisfaction has established us as a trusted partner in the industry. Partner with us to transform your business with cutting-edge technology tailored just for you.
                    </p>
        
                    <h2>Proudly Supported by</h2>
        
                
                </container>
                <LogoSlider1/>
                <container className='card-container'>
                
        
                    <h2>Our Mission</h2>
                <p className='para'>
                At Virtual Business Solutions (SMC-Private) Limited, our mission is to empower businesses with cutting-edge custom software and comprehensive ERP solutions that drive efficiency, innovation, and growth. We are committed to delivering exceptional value through our tailored services, ensuring that every client's unique needs are met with precision and expertise. Our dedicated team of professionals harnesses the latest technologies to transform challenges into opportunities, fostering a culture of continuous improvement and excellence. As a trusted partner, we strive to exceed expectations, enabling our clients to thrive in an ever-evolving digital landscape. Virtual Business Solutions is where your vision meets our technological prowess, creating a synergy that propels your business forward.
                </p>
        
                
                </container>
        
                
                </div>
            } />
                
            </Routes>
            <SiteFooter/>
        </Router>
      
      
      
      
        
      
    </div>
  );
}

export default App;
