import React, { useState }  from 'react';
import './slider.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../Assets/Akson.jpg';
import image2 from '../Assets/Muslim.png';
import image3 from '../Assets/pak.jpg';
import image4 from '../Assets/medicair.jpg';
import image5 from '../Assets/butt.png';
import image6 from '../Assets/cocoa.png';
import image7 from '../Assets/sauda.png';
import image8 from '../Assets/simla.png';
import image9 from '../Assets/centrum.jpg';
import image10 from '../Assets/euro.png';
import image11 from '../Assets/nafees.png';
import image12 from '../Assets/babarkhan.png';
import image13 from '../Assets/signcraft.jpg';
import image14 from '../Assets/waffles.jpg';
import image15 from '../Assets/watan.png';
import image16 from '../Assets/deshole.jpg';


    // Your component logic
  
    const LogoSlider = () => {
        

          const [settings, setSettings] = useState({
            
            infinite: true,
            slidesToShow: 5, // Default value (adjust as needed)
            slidesToScroll: 3,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 5000,
            cssEase: 'linear',
          });
        
          // Update slidesToShow based on screen width
          const updateSlidesToShow = () => {
            const screenWidth = window.innerWidth;
            let newSlidesToShow = 6; // Default value
        
            if (screenWidth < 768) {
              newSlidesToShow = 3; // Show only 1 slide on small screens
            } else if (screenWidth < 1024) {
              newSlidesToShow = 4; // Show 2 slides on medium screens
            }
        
            setSettings((prevSettings) => ({
              ...prevSettings,
              slidesToShow: newSlidesToShow,
            }));
          };

        
          // Call the function initially and on window resize
          React.useEffect(() => {
            updateSlidesToShow();
            window.addEventListener('resize', updateSlidesToShow);
            return () => {
              window.removeEventListener('resize', updateSlidesToShow);
            };
          }, []);

        const logos = [
          {imgPath: image1},
          {imgPath: image2},
          {imgPath: image3},
          {imgPath: image4},
          {imgPath: image5},
          {imgPath: image6},
          {imgPath: image7},
          {imgPath: image8},
          {imgPath: image9},
          {imgPath: image10},
          {imgPath: image11},
          {imgPath: image12},
          {imgPath: image13},
          {imgPath: image14},
          {imgPath: image15},
          {imgPath: image16},
          // Add more logos here
        ];
      
        return (
            <div className="logo-slider">
              
              <Slider {...settings}>
                {logos.map((logo, index) => (
                  <div key={index} className="container">
                    <img className='slider-img' src={logo.imgPath} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          );
        };
      
  
  
  export default LogoSlider;